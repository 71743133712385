<template>
	<div class="home">
		<table-approvinglevel />
	</div>
</template>

<script>
import tableApprovingLevel from '@/components/pages/maint/approvinglevel/tableApprovingLevel.vue';

export default {
	components: {
		'table-approvinglevel': tableApprovingLevel,
	}
};
</script>

<style>

</style>
