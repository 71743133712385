<template>
	<v-dialog v-model="dialog" persistent max-width="60%" scrollable>
		<template v-slot:activator="{ on }">
			<app-add-button v-on="on" v-bind:module="'approvinglevel'"></app-add-button>
		</template>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-btn icon dark @click="close">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>

							<v-flex xs12 sm6 md6>
								<v-text-field type="number" :rules="requiredNumber" v-model="editedItem.level_code" label="Level Code" required></v-text-field>
							</v-flex>

							<v-flex xs12 sm6 md6>
								<v-text-field :rules="requiredText" v-model="editedItem.level_name" label="Level Name" required></v-text-field>
							</v-flex>

							<v-flex xs12 sm6 md6>
								<v-text-field type="number" :rules="requiredNumber" v-model="editedItem.min_amount" label="Minimum Amount" required></v-text-field>
							</v-flex>

							<v-flex xs12 sm6 md6>
								<v-text-field type="number" :rules="requiredNumber" v-model="editedItem.max_amount" label="Maximum Amount" required></v-text-field>
							</v-flex>

						</v-layout>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue" dark  @click="save">Save</v-btn>
					<v-btn color="red" text @click="close">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: false,
		requiredText: [v => !!v || 'This field is required'],
		requiredNumber: [v => !!v || 'This field must be a number and is required']
	}),

	computed: {
		...mapGetters('approvinglevel', [
			'approvinglevels',
			'editedItem',
			'defaultItem',
			'dialog',
			'editedIndex',
			'formTitle',
			'isLoading',
			'valid'
		]),
		...mapGetters({
			currUser: 'auth/currUser'
		})
	},
	methods: {
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('approvinglevel/setValid', true);
				this.$store.dispatch('approvinglevel/setDialog', false);
				this.$store.dispatch('approvinglevel/setEditedIndex', -1);
				//this.isvalid = false;
			}, 2000);
		},

		async save() {
			let {id, level_code, level_name, min_amount, max_amount} = this.editedItem;
			let edit_by = this.currUser.id;
			let index = this.editedIndex;

			this.$store.dispatch('approvinglevel/setValid', this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if (this.valid) {
				this.$store.dispatch('approvinglevel/setisLoading', true);

				if (index > -1) {
					await this.$store.dispatch('approvinglevel/updateApprovingLevel', {
						data: {
							id,
							level_code,
							level_name,
							min_amount,
							max_amount,
							edit_by
						},
						index
					});
				} else {
					await this.$store.dispatch('approvinglevel/saveApprovingLevel', {
						level_code,
						level_name,
						min_amount,
						max_amount,
						edit_by
					});
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		}
	}
};
</script>