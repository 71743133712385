<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="approvinglevels"
      class="elevation-1"
      :search="search"
			dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Approving Levels</v-toolbar-title>
          <v-spacer></v-spacer>
			<v-text-field
			v-model="search"
			append-icon="search"
			label="Search"
			single-line
			hide-details
			></v-text-field> 
			<vue-excel-xlsx
				:data="approvinglevels"
				:columns="columnHeaders"
				:file-name="module_name"
				>
			<v-btn icon alt class="mt-2" :color="approvinglevels.length=== 0 ? 'gray' : 'primary'" :disabled="approvinglevels.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
        </v-toolbar>
      </template>
			<template v-slot:item.min_amount="{ item }">
				<span>{{formatAmount(item.min_amount)}}</span>
			</template>
			<template v-slot:item.max_amount="{ item }">
				<span>{{formatAmount(item.max_amount)}}</span>
			</template>
      <template v-slot:item.action="{ item }">
        <v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 3"
		>
			mdi-pencil-off
		</v-icon>
		<v-icon
			color="primary"
			v-else
			class="mr-2"
			small
			@click="editItem(item)"
		>
			edit
		</v-icon>
			<v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 4"
		>
			mdi-delete-off
		</v-icon>
		<v-icon
			small
			v-else
			color="red"
			@click="deleteItem(item)"
		>
			delete
		</v-icon>
      </template>
    </v-data-table>
    <create-approvinglevel  v-if="role_access > 1"/>
  </div>
</template>

<script>
import createApprovingLevel from './createApprovingLevel.vue';
import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
	data(){
		return{
			module_name: 'Approving Levels',
			role_access: 1,
			search: ''
		};
	},
	components: {
		'create-approvinglevel': createApprovingLevel
	},
	computed: {
		...mapGetters('approvinglevel', [
			'approvinglevels',
			'headers',
			'columnHeaders',
			'editedItem',
			'defaultItem',
			'dialog',
			'editedIndex',
			'isLoading',
			'valid'
		]),
		...mapGetters({
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		download () {
			//Download
		},
		async editItem (item) {
			this.$store.dispatch('approvinglevel/setEditedIndex', this.approvinglevels.indexOf(item));
			// this.$store.dispatch('approvinglevel/setEditedItems', Object.assign({}, item));
			await this.$store.dispatch('approvinglevel/getDetails', item.id);
			this.$store.dispatch('approvinglevel/setDialog', true);
			this.$store.dispatch('approvinglevel/setValid', true);
			this.$store.dispatch('approvinglevel/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.level_name} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('approvinglevel/setEditedIndex', this.approvinglevels.indexOf(item));
					this.$store.dispatch('approvinglevel/deleteApprovingLevel', item);
				}
			});
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		}
	}
};
</script>